const tenant_members = {
    members: 'Участники',
    invitations: 'Приглашения',
    invite_members: 'Пригласить участников',
    user: 'Пользователь',
    roles: 'Роли',
    admin: 'Администратор',
    collaborator: 'Соавтор',
    invitation_status: 'Статус приглашения',
    inviter: 'Пригласивший',
    expiration_date: 'Дата окончания',
    invite_modal: {
        title: 'Пригласить людей в Seitrace Cloud',
        subtitle: 'Для приглашения участников в организацию они должны принять приглашение.',
        to: 'Кому',
        added_as: 'Добавлено как роль',
        email_input_placeholder: 'johndoe@example.com',
    },
    invitation_statuses: {
        pending: 'В ожидании',
        accepted: 'Принято',
        expired: 'Истекло',
        revoked: 'Отозвано',
    },
    invitation_empty_placeholder: {
        title: 'Пригласить членов команды',
        description: 'Ваша организация пока что не пригласила никаких участников.\nДля помощи в вашем интеграции рассмотрите возможность добавления большего количества участников или администраторов.',
    },
    menu_options: {
        edit: 'Изменить роль арендатора',
        delete: 'Удалить пользователя из арендатора',
        resend_invite: 'Отправить приглашение повторно',
        revoke: 'Отозвать приглашение',
        delete_invitation_record: 'Удалить эту запись о приглашении',
    },
    edit_modal: {
        title: 'Изменить роли у {{name}}',
    },
    delete_user_confirm: 'Вы уверены, что хотите удалить этого пользователя из данного арендатора?',
    assign_admin_confirm: 'Вы уверены, что хотите сделать выбранного пользовател(ей) администратором? Предоставление прав админа даст пользователю(ям) следующие разрешения.<ul><li>Изменить тарифный план арендатора</li><li>Добавлять или удалять соавторов</li><li>Удалять арендатора</li></ul>',
    revoke_invitation_confirm: 'Вы уверены, что хотите отозвать это приглашение?',
    delete_invitation_confirm: 'Вы уверены, что хотите удалить эту запись о приглашении?',
    messages: {
        invitation_sent: 'Приглашение отправлено.',
        invitation_revoked: 'Приглашение отозвано.',
        invitation_resend: 'Приглашение отправлено повторно.',
        invitation_deleted: 'Запись о приглашении удалена.',
    },
    errors: {
        email_required: 'Электронная почта приглашенного обязательна.',
        email_exists: 'Адрес электронной почты уже существует.',
        member_exists: 'Этот пользователь уже является участником этой организации.',
        pending_invitation_exists: 'Существует незавершенное приглашение. Удалите связанный адрес электронной почты или отзовите приглашение.',
        invalid_email: 'Адрес электронной почты недействителен. Пожалуйста, убедитесь, что он находится в правильном формате.',
        max_member_limit: 'Вы достигли максимального количества участников ({{limit}}) для этого арендатора.',
    },
};
export default Object.freeze(tenant_members);
