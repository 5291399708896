const get_started = {
    page_title: 'Начало работы',
    title: 'Что-то, что поможет вам добиться успеха',
    subtitle: 'Несколько вещей, которые вы можете сделать, чтобы быстро получить ценность от Seitrace',
    develop: {
        title: 'Разработка: Вам понадобится 5 минут, чтобы интегрировать ваше приложение',
        title_cloud: 'Разработка: Безопасная интеграция вашего приложения за минуты',
        subtitle_cloud: 'Или займет 5 минут для интеграции вашего приложения с нашими готовыми SDK и руководствами.',
    },
    customize: {
        title: 'Настроить: Создайте отличный вариант входа в систему',
        preview: {
            title: 'Проверьте живую трансляцию присоединения к системе, которое вы только что настроили',
            subtitle: 'Попробуйте пробный вариант входа в систему Seitrace, чтобы узнать, как это работает',
        },
        connector: {
            title: 'Добавьте больше коннекторов, чтобы поддерживать больше способов входа через социальные сети',
            subtitle: 'Попробуйте вход без пароля и включите защищенный и плавный опыт для ваших клиентов',
        },
        continue_customizing: 'Продолжайте настройку',
        try_now: 'Попробуйте сейчас',
        add_more: 'Добавьте еще',
    },
    secure: {
        title: 'Защита: Защитите свои ресурсы',
    },
    manage: {
        title: 'Управление: Определите контроль доступа для вашего продукта и пользователей',
        rbac: {
            title: 'Добавьте управление доступом на основе ролей, чтобы защитить ваши ресурсы',
            subtitle: 'Управляйте вашим ресурсом через масштабируемую авторизацию ролей для различных случаев использования',
        },
        create_roles: 'Создать роли',
    },
    view_all: 'Посмотреть все →',
};
export default Object.freeze(get_started);
